const state = {
  route: {},
  curPlayItem: null,
  svaeProgress: "",
  curPlayTime: 0,
  curPlayProgress: 0,
  seektime: 0,
  screenshot: null,
  userName: null,
};

export default state;
