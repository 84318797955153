import Vuex from "vuex";
import state from "./state";
// import mutations from './mutation';
import * as getters from "./getters";
// import * as actions from './actions'
import _ncStoreModules from "_nc/store";
Vue.use(Vuex);
export default new Vuex.Store({
  state,
  getters,
  // mutations,
  // actions,
  modules: {
    ...$platformStore,
    ..._ncStoreModules,
  },
});
